import axios from '@/axios'
import { SCRIM_MAX_ROUND_TIME_IN_SECONDS } from '@/constants'
import agentCompositionToApi from '@/utils/agentCompositionToApi'
import { periodToApi } from '@/utils/period'
import { prepareScrimList } from '@/utils/prepareScrimData'
import { timeToMillis } from '@/utils/timeToMillis'
/**
 * @param {string} title
 * @param {API_AND_OR_OPTION} agent_option
 * @param {API_AND_OR_OPERATOR} agent_opr
 * @param {Array<Array<string>>} agent_comps
 * @param {Array<string>} agent_ids
 * @param {API_AND_OR_OPTION} agent_vs_option
 * @param {API_AND_OR_OPERATOR} agent_vs_opr
 * @param {Array<Array<string>>} agent_vs_comps
 * @param {Array<string>} agent_vs_ids
 * @param {Array<string>} map_ids
 * @param {string|null} begin_time
 * @param {string|null} end_time
 * @param {PACKED_PERIOD|UNPACKED_PERIOD} period
 * @param {'created'|'rounds_played'|'rounds_won_diff'} sortBy
 * @param {'created'|'rounds_played'|'rounds_won_diff'} sort_by
 * @param {boolean} sortDesc
 * @param {boolean} sort_desc
 * @param {number} count
 * @param {number} index
 * @param cancelToken
 * @return {Promise<{total: number, data: API_SEARCH_SCRIM}>}
 */
export const findScrims = async (
  {
    title = null,

    agent_option = 'or',
    agent_comps = [],
    agent_ids = [],
    agents_and = agent_option === 'and' ? agent_ids : [],
    agents_or = agent_option === 'or' ? agent_ids : [],

    agent_vs_option = 'or',
    agent_vs_comps = [],
    agent_vs_ids = [],
    vs_agents_and = agent_vs_option === 'and' ? agent_vs_ids : [],
    vs_agents_or = agent_vs_option === 'or' ? agent_vs_ids : [],

    map_ids = [],

    begin_time = null,
    end_time = null,
    period = { begin_time, end_time },

    sortBy = 'created',
    sort_by = sortBy,
    sortDesc = true,
    sort_desc = sortDesc,

    count = 20,
    index = 1,
    limit = count,
    offset = (index - 1) * limit,
  } = {},
  { cancelToken } = {}
) => {
  const query = {
    title,

    agents_and,
    agents_or,
    vs_agents_and,
    vs_agents_or,
    agent_comps: agent_comps ? agent_comps.map(agentCompositionToApi) : [],
    vs_agent_comps: agent_vs_comps ? agent_vs_comps.map(agentCompositionToApi) : [],

    maps: map_ids,
    ...periodToApi(period),

    order_by: (sort_desc ? '-' : '') + sort_by,
    limit,
    offset,
  }

  const response = await axios.get('/val/v3/scrim/matches', {
    params: query,
    cancelToken,
  })
  return {
    data: prepareScrimList(response.data?.scrim_matches) || [],
    total: response.data?.count || 0,
  }
}

export const getScrim = async (id, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v3/scrim/matches/${encodeURIComponent(id)}`, { cancelToken })
  return response.data
}

export const editScrim = async (scrimId, { name, created }) => {
  return await axios.patch(`/val/v3/scrim/matches/${encodeURIComponent(scrimId)}`, {
    name,
    created,
  })
}

export const createScrim = async ({ name, map, created }) => {
  const response = await axios.post('/val/v3/scrim/matches', { name, map, created })
  return response.data
}

export const createScrimTeam = async ({ scrimId, team, isSelfTeam, winLastRound }) => {
  const response = await axios.post(`/val/v3/scrim/matches/${scrimId}/teams`, {
    team,
    is_self_team: isSelfTeam,
    win_last_round: winLastRound,
  })
  return response.data
}

export const createTeamTemp = async ({ name }) => {
  const response = await axios.post(`/val/v3/scrim/tmp-teams`, {
    as_esport_team: '',
    name,
  })
  return response.data
}

export const createScrimReplay = async ({ scrimId, videoPlatform, videoId, videoStartTime, videoEndTime }) => {
  const response = await axios.post(`/val/v3/scrim/matches/${scrimId}/replay`, {
    video_platform: videoPlatform,
    video_id: videoId,
    video_start_time: videoStartTime,
    video_end_time: videoEndTime,
  })
  return response.data
}

export const getScrimAdvancedOverview = async (id, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v3/scrim/matches/${encodeURIComponent(id)}/data/advanced`, { cancelToken })
  return response.data
}

export const getScrimAdvancedRoundInfo = async (id, roundNum, { cancelToken } = {}) => {
  const response = await axios.get(
    `/val/v3/scrim/matches/${encodeURIComponent(id)}/data/advanced/rounds/${encodeURIComponent(roundNum)}`,
    { cancelToken }
  )
  const roundInfo = response.data
  if (roundInfo.replay_video_start_time && roundInfo.replay_video_end_time) {
    roundInfo.round_length_millis =
      timeToMillis(roundInfo.replay_video_end_time) - timeToMillis(roundInfo.replay_video_start_time)
    if (roundInfo.round_length_millis > SCRIM_MAX_ROUND_TIME_IN_SECONDS * 1000) {
      roundInfo.round_length_millis = SCRIM_MAX_ROUND_TIME_IN_SECONDS * 1000
    }
  }
  return roundInfo
}

export const getScrimReplayInfo = async (scrimId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v3/scrim/matches/${encodeURIComponent(scrimId)}/replay`, {
    cancelToken,
  })
  return response.data
}
