import abbrv from '@/utils/abbrv'

export const prepareScrimList = scrims => {
  return (
    scrims?.map(scrim => {
      return {
        ...scrim,
        teams: scrim.teams_through.map(team => {
          return {
            ...team,
            ...team.team_expand,
            abbr: team.team_expand.abbr || team.abbr || abbrv(team.team_expand.name, { length: 5 }),
            id: team.is_self_team ? 'self' : team.team_expand.name.toLowerCase(),
          }
        }),
      }
    }) || []
  )
}

export function prepareScrimData(scrim, advanced, replayInfo) {
  if (!this) {
    throw new Error('Missing this as vue component')
  }
  const result = {
    ...scrim,
    replay: replayInfo,
    teams: scrim.teams_through.map(team => {
      return {
        ...team,
        ...team.team_expand,
        abbr: team.team_expand.abbr || team.abbr || abbrv(team.team_expand.name, { length: 5 }),
        id: team.is_self_team ? 'self' : team.team_expand.name.toLowerCase(),
        players: (
          scrim.participants_through?.filter(participant => participant.team_side === team.team_side) || []
        ).map((player, index) => ({
          ...player,
          name:
            player.name ||
            `${team.team_expand.abbr || team.team_expand.name} ${
              this.$store.getters['static/getAgentById'](player.agent)?.name || `Player ${index}`
            }`,
        })),
      }
    }),
    ...(advanced?.length
      ? {
          roundData: advanced?.reduce((acc, round) => {
            acc[round.round_num] = {
              ...round,
              kills: round.killfeed_detections_expand?.events.map(kill => ({
                ...kill,
                killer_puuid: kill.source_puuid,
                victim_puuid: kill.target_puuid,
              })),
            }
            return acc
          }, {}),
        }
      : { roundData: advanced?.rounds || {} }),
  }

  result.roundScores = [
    ...(result.roundData
      ? Object.values(result.roundData)?.map(round => {
          return {
            ...round?.score,
            round_num: round.round_num,
            winning_team_side: calcRoundWin(round.round_num, result),
          }
        })
      : []),
  ]

  return result
}

const calcRoundWin = (roundNum, scrim) => {
  if (roundNum < scrim.rounds_played - 1) {
    return scrim.roundData[roundNum].score_blue < scrim.roundData[roundNum + 1].score_blue ? 'Blue' : 'Red'
  } else {
    return scrim.teams.find(team => team.win_last_round).team_side
  }
}
